.faq {
  position: relative;

  &__wrapper {
    border-radius: 1.2rem;
    padding-right: 3rem;
    padding-left: 3rem;
    background-color: var(--clr-default-600);

    &:not(:last-child) {
      margin-bottom: 1.2rem;

      @media (max-width: em(992)) {
        margin-bottom: 2rem;
      }

      @media (max-width: em(575)) {
        margin-bottom: 1.5rem;
      }
    }

    &.active {
      .faq__question {
        span {
          background: initial;
          background: #f3f5f6;
          transition: transform var(--transition),
            background-color var(--transition);
        }

        &::before {
          transform: rotate(-180deg);
          transition: transform var(--transition);
        }
      }

      .faq__answer {
        padding-bottom: 3rem;
        font-weight: 400;
        font-size: fluid-font(1.8rem, 1.6rem);
        transition: max-height var(--transition),
          padding-bottom var(--transition);

        @media (max-width: em(575)) {
          padding-bottom: 2rem;
        }
      }
    }

    @media (max-width: em(575)) {
      padding-left: 2rem;
    }
  }

  &__question {
    position: relative;
    padding-top: 3rem;
    padding-right: 4rem;
    padding-bottom: 2.5rem;
    width: 100%;
    font-weight: 600;
    font-size: fluid-font(2rem, 1.6rem);
    line-height: 150%;
    color: var(--clr-default-100);

    &::before {
      content: "";
      position: absolute;
      right: 0;
      top: 4rem;
      width: 2.2rem;
      height: 1.2rem;
      background-image: url(../img/faq/arrow.svg);
      background-size: cover;
      background-repeat: no-repeat;
      transition: transform var(--transition);

      @media (max-width: em(767)) {
        right: -1rem;
        top: 3rem;
      }
    }

    @media (max-width: em(767)) {
      padding-top: 2rem;
      padding-bottom: 2rem;
    }
  }

  &__answer {
    overflow: hidden;
    max-height: 0;
    box-sizing: content-box;
    font-size: fluid-font(1.8rem, 1.6rem);
    line-height: 150%;
    color: var(--clr-default-300);
    transition: max-height var(--transition), padding-bottom var(--transition);

    p:not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }
}
