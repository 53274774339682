.exclusive {
  background-image: -webkit-image-set(
    url(../img/exclusive/bg@2x.jpg) 2x,
    url(../img/exclusive/bg@1x.jpg) 1x
  );
  background-size: cover;
  background-repeat: no-repeat;

  &__wrapper {
    position: relative;
    padding-top: 11rem;
    padding-bottom: 11rem;

    @media (max-width: em(992)) {
      padding-top: 10rem;
      padding-bottom: 10rem;
    }
  }

  &__content {
    max-width: 72rem;

    @media (max-width: em(1500)) {
      max-width: initial;
    }
  }

  &__title {
    font-size: clamp( 3.6rem, 2.0179775281rem + 4.9438202247vw, 8rem );
    line-height: 90%;
    text-transform: uppercase;
    color: var(--clr-default-100);

    @media (max-width: em(1200)) {
      text-align: center;
    }
  }

  &__subtitle {
    margin-bottom: 5rem;
    font-size: clamp( 2.8rem, 2.4404494382rem + 1.1235955056vw, 3.8rem );
    text-transform: uppercase;
    color: var(--clr-default-100);

    @media (max-width: em(1200)) {
      text-align: center;
    }

    @media (max-width: em(575)) {
      margin-bottom: 3rem;
    }
  }

  &__link {
    min-width: 24rem;

    @media (max-width: em(1200)) {
      margin-right: auto;
      margin-left: auto;
    }
  }

  &__image {
    position: absolute;
    right: -43rem;
    bottom: -0.5rem;
    width: 111rem;
    height: 65rem;

    @media (max-width: em(1500)) {
      display: none;
    }
  }
}
