.bonuses {
  background-image: -webkit-image-set(
    url(../img/bonuses/bg@2x.jpg) 2x,
    url(../img/bonuses/bg@1x.jpg) 1x
  );
  background-size: cover;
  background-repeat: no-repeat;

  &__wrapper {
    position: relative;
    padding-top: 18rem;
    padding-bottom: 18rem;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 34rem;
      height: 71rem;
      background-image: -webkit-image-set(
        url(../img/bonuses/decor@2x.png) 2x,
        url(../img/bonuses/decor@1x.png) 1x
      );
      background-size: cover;
      background-repeat: no-repeat;

      @media (max-width: em(1200)) {
        display: none;
      }
    }

    @media (max-width: em(992)) {
      padding-top: 10rem;
      padding-bottom: 10rem;
    }
  }

  &__content {
    margin-left: auto;
    max-width: 76rem;

    @media (max-width: em(1200)) {
      max-width: initial;
    }
  }

  &__title {
    font-weight: 700;
    font-size: fluid-font(8rem, 3.6rem);
    text-transform: uppercase;
    color: var(--clr-default-100);

    @media (max-width: em(1200)) {
      text-align: center;
    }
  }

  &__subtitle {
    margin-bottom: 5rem;
    font-weight: 400;
    font-size: fluid-font(3.8rem, 2.8rem);
    color: var(--clr-default-100);

    @media (max-width: em(1200)) {
      text-align: center;
    }
  }

  &__link {
    @media (max-width: em(1200)) {
      margin-left: auto;
      margin-right: auto;
    }
  }
}
