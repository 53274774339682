/* stylelint-disable */

/* stylelint-disable */

/* stylelint-disable scss/operator-no-unspaced */

/* stylelint-disable scss/dollar-variable-colon-newline-after */

@font-face {
  font-family: "Rubik";
  src: url("../fonts/Rubik-Bold.woff2") format("woff2");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Rubik";
  src: url("../fonts/Rubik-Medium.woff2") format("woff2");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Rubik";
  src: url("../fonts/Rubik-Regular.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

html {
  font-family: var(--font-family);
  font-weight: var(--font-weight);
  font-size: 62.5%;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  scroll-behavior: smooth;
}

html,
body {
  height: 100%;
}

body {
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  text-decoration: none;
}

img {
  height: auto;
  max-width: 100%;
  object-fit: cover;
}

button {
  border: none;
  text-align: left;
  background-color: transparent;
  cursor: pointer;
}

ul,
ol {
  list-style: none;
}

input {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: #fff;
}

input::-webkit-search-decoration,
input::-webkit-search-cancel-button,
input::-webkit-search-results-button,
input::-webkit-search-results-decoration {
  display: none;
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.focus-visible {
  outline: auto 1px;
}

.ios .lock {
  position: relative;
}

/* Forms
   ========================================================================== */

/**
 * 1. Change the font styles in all browsers.
 * 2. Remove the margin in Firefox and Safari.
 */

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */

button,
select {
  text-transform: none;
}

/**
 * Correct the inability to style clickable types in iOS and Safari.
 */

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

/**
 * Remove the inner border and padding in Firefox.
 */

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
 * Restore the focus styles unset by the previous rule.
 */

button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

:root {
  --font-family: "Rubik", sans-serif;
  --font-weight: 400;
  --content-width: 118rem;
  --container-offset: 1.5rem;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));
  --transition: 0.3s;
  --clr-default-100: #fff;
  --clr-default-300: rgba(255, 255, 255, 0.48);
  --clr-default-400: #808082;
  --clr-default-500: rgba(255, 255, 255, 0.08);
  --clr-default-600: rgba(255, 255, 255, 0.12);
  --clr-default-700: rgba(36, 36, 36, 0.24);
  --clr-default-800: #0B0C10;
  --clr-default-900: #000;
  --clr-primary-400: #4EE436;
  --clr-primary-500: #37A425;
  --clr-primary-600: #FA7600;
}

body {
  font-size: clamp(
    1.6rem,
    1.5280898876rem + 0.2247191011vw,
    1.8rem
  );
  color: var(--clr-default-300);
  background-color: var(--clr-default-800);
}

body.lock {
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  overscroll-behavior: none;
}

.wrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  min-width: 32rem;
  min-height: 100%;
}

.wrapper::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transition: opacity var(--transition), visibility var(--transition);
}

.wrapper[data-overlay=true]::before {
  opacity: 1;
  visibility: visible;
  transition: opacity var(--transition), visibility var(--transition);
}

main {
  flex-grow: 1;
}

.container {
  margin-right: auto;
  margin-left: auto;
  padding-right: var(--container-offset);
  padding-left: var(--container-offset);
  width: 100%;
  max-width: var(--container-width);
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.section {
  padding-top: 19rem;
}

.title {
  margin-bottom: 2rem;
  font-weight: 600;
  font-size: clamp(
    3.2rem,
    2.8404494382rem + 1.1235955056vw,
    4.2rem
  );
  line-height: 130%;
  text-transform: uppercase;
  color: var(--clr-default-100);
}

.description {
  font-size: clamp(
    1.6rem,
    1.5280898876rem + 0.2247191011vw,
    1.8rem
  );
  line-height: 150%;
}

.main__link {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10rem;
  padding-right: 2rem;
  padding-left: 2rem;
  width: max-content;
  min-width: 36rem;
  min-height: 7rem;
  max-width: 45rem;
  box-shadow: 0px 5px 0px #205e15, 0px 14px 48px rgba(55, 164, 37, 0.35);
  font-weight: 700;
  font-size: clamp(
    1.8rem,
    1.7280898876rem + 0.2247191011vw,
    2rem
  );
  line-height: 120%;
  text-align: center;
  text-transform: uppercase;
  color: var(--clr-default-100);
  background-color: var(--clr-primary-500);
  transition: box-shadow var(--transition);
}

.main__link:hover {
  box-shadow: 0px 2px 0px #205e15, 0px 14px 48px rgba(55, 164, 37, 0.35);
  transition: box-shadow var(--transition);
}

.main__link:active {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), #37a425;
}

.main__link--bonus {
  box-shadow: 0px 5px 0px #af5300, 0px 14px 48px rgba(175, 83, 0, 0.35);
  background-color: var(--clr-primary-600);
}

.main__link--bonus:hover {
  box-shadow: 0px 2px 0px #af5300, 0px 14px 48px rgba(175, 83, 0, 0.35);
}

.main__link--bonus:active {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), #fa7600;
}

.menu {
  margin-left: auto;
}

.menu__list {
  display: flex;
  align-items: center;
}

.menu__item {
  position: relative;
}

.menu__item::before {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -4px;
  width: 0;
  height: 2px;
  background-color: var(--clr-primary-500);
  transform: translate(-50%, 0);
  transition: width 0.3s;
}

.menu__item:hover::before {
  width: 100%;
  transition: width 0.3s;
}

.menu__item:not(:last-child) {
  margin-right: 3.2rem;
}

.menu__item a {
  font-weight: 500;
  color: var(--clr-default-100);
}

.logo {
  margin-right: 2rem;
  max-width: 17rem;
}

.bonus {
  margin-left: 5rem;
}

.bonus a {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.2rem solid var(--clr-primary-400);
  border-radius: 10rem;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  width: max-content;
  min-width: 16rem;
  min-height: 5rem;
  max-width: 25rem;
  font-weight: 700;
  line-height: 70%;
  text-align: center;
  text-transform: uppercase;
  color: var(--clr-primary-400);
  transition: box-shadow var(--transition);
}

.bonus a:hover {
  box-shadow: 0px 14px 48px rgba(55, 164, 37, 0.35);
  transition: box-shadow var(--transition);
}

.burger {
  display: none;
  margin-left: 2rem;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
}

.header__wrapper {
  display: flex;
  align-items: center;
  border-bottom: 0.2rem solid var(--clr-default-500);
  min-height: 10rem;
}

.footer {
  margin-bottom: 5rem;
}

.footer__wrapper {
  display: flex;
  flex-direction: column;
}

.footer__logo {
  margin-bottom: 1.6rem;
}

.footer__text {
  margin-bottom: 2rem;
}

.footer__text p:not(:last-child) {
  margin-bottom: 1.2rem;
}

.footer__policy {
  font-size: clamp(
    1.4rem,
    1.2561797753rem + 0.4494382022vw,
    1.8rem
  );
}

.hero {
  position: relative;
}

.hero::before {
  content: "";
  position: absolute;
  left: 0;
  top: -10rem;
  z-index: -1;
  width: 100%;
  height: 94rem;
  background-image: -webkit-image-set(url(../img/hero/bg@2x.png) 2x, url(../img/hero/bg@1x.png) 1x);
  background-size: cover;
  background-repeat: no-repeat;
}

.hero__wrapper {
  padding-top: 18rem;
  padding-bottom: 37rem;
}

.hero__content {
  position: relative;
  z-index: 1;
  max-width: 67rem;
}

.hero__title {
  padding-bottom: 2.4rem;
  font-weight: 600;
  font-size: clamp(
    3.8rem,
    3.2247191011rem + 1.797752809vw,
    5.4rem
  );
  text-transform: uppercase;
  color: var(--clr-default-100);
}

.hero__text {
  margin-bottom: 5rem;
}

.hero__text p:not(:last-child) {
  margin-bottom: 2rem;
}

.hero__image {
  position: absolute;
  right: -35rem;
  top: 9rem;
  width: 129rem;
  height: 116rem;
}

.info {
  position: relative;
  z-index: 1;
  margin-top: 3rem;
}

.info__title {
  text-align: center;
}

.info__text {
  margin-right: auto;
  margin-bottom: 3rem;
  margin-left: auto;
  max-width: 80rem;
  text-align: center;
}

.info__text p:not(:last-child) {
  margin-bottom: 1.5rem;
}

.info__table {
  overflow: auto;
  border: 0.2rem solid var(--clr-default-500);
  border-radius: 3rem;
  padding: 5rem;
  background-color: var(--clr-default-700);
}

.info__table table {
  border-collapse: collapse;
  min-width: 61rem;
}

.info__table table tr:not(:last-child) {
  border-bottom: 0.2rem solid var(--clr-default-600);
}

.info__table table td {
  vertical-align: top;
  padding-top: 2.5rem;
  padding-right: 1.5rem;
  padding-bottom: 2.5rem;
  padding-left: 1.5rem;
  line-height: 150%;
}

.info__table table td:first-child {
  min-width: 45rem;
}

.info__table table td:last-child {
  font-size: 1.6rem;
  color: var(--clr-default-100);
}

.info__table table td a {
  text-decoration: underline;
  color: var(--clr-primary-400);
}

.benefits__wrapper {
  display: flex;
}

.benefits__list {
  min-width: 59rem;
}

.benefits__list:first-child {
  margin-right: 3rem;
}

.benefits__item {
  padding-left: 3.5rem;
  font-size: clamp(
    1.8rem,
    1.7280898876rem + 0.2247191011vw,
    2rem
  );
  line-height: 150%;
  color: var(--clr-default-100);
}

.benefits__item:not(:last-child) {
  margin-bottom: 1rem;
}

.benefits__item span {
  color: var(--clr-default-400);
}

.benefits__item--pros,
.benefits__item--cons {
  position: relative;
}

.benefits__item--pros::before,
.benefits__item--cons::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 2.4rem;
  height: 3.6rem;
  background-size: contain;
  background-repeat: no-repeat;
}

.benefits__item--pros::before {
  background-image: url(../img/benefits/pros.svg);
}

.benefits__item--cons::before {
  background-image: url(../img/benefits/cons.svg);
}

.registration__wrapper {
  display: flex;
  justify-content: space-between;
}

.registration__image {
  margin-right: 2rem;
  max-width: 42rem;
}

.registration__image img {
  height: 100%;
}

.registration__content {
  max-width: 69rem;
}

.registration__text {
  margin-bottom: 2.5rem;
}

.registration__list {
  list-style: decimal;
  margin-bottom: 5rem;
  padding-left: 2rem;
  line-height: 150%;
}

.registration__list-item:not(:last-child) {
  margin-bottom: 2rem;
}

.registration__mark-list {
  list-style: disc;
  padding-top: 1rem;
  padding-left: 2.5rem;
}

.registration__mark-list li {
  line-height: 150%;
}

.slots__text {
  margin-bottom: 3rem;
}

.slots__text p:not(:last-child) {
  margin-bottom: 2rem;
}

.slots__image {
  margin-bottom: 5rem;
  border-radius: 1.2rem;
  max-width: 120rem;
}

.slots__table {
  overflow: auto;
  border: 0.1rem solid var(--clr-default-500);
  border-radius: 3.2rem;
  padding: 3rem;
  background-color: var(--clr-default-700);
}

.slots__table table {
  border-collapse: collapse;
  min-width: 100%;
}

.slots__table table thead tr {
  border-bottom: 0.2rem solid var(--clr-default-600);
}

.slots__table table thead td {
  vertical-align: top;
  padding-top: 2.5rem;
  padding-right: 1.5rem;
  padding-bottom: 3rem;
  padding-left: 1.5rem;
  font-size: 1.8rem;
}

.slots__table table tbody tr:not(:last-child) {
  border-bottom: 0.2rem solid var(--clr-default-600);
}

.slots__table table tbody td {
  vertical-align: top;
  padding-top: 2.5rem;
  padding-right: 1.5rem;
  padding-bottom: 3rem;
  padding-left: 1.5rem;
  color: var(--clr-default-100);
}

.bonuses {
  background-image: -webkit-image-set(url(../img/bonuses/bg@2x.jpg) 2x, url(../img/bonuses/bg@1x.jpg) 1x);
  background-size: cover;
  background-repeat: no-repeat;
}

.bonuses__wrapper {
  position: relative;
  padding-top: 18rem;
  padding-bottom: 18rem;
}

.bonuses__wrapper::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 34rem;
  height: 71rem;
  background-image: -webkit-image-set(url(../img/bonuses/decor@2x.png) 2x, url(../img/bonuses/decor@1x.png) 1x);
  background-size: cover;
  background-repeat: no-repeat;
}

.bonuses__content {
  margin-left: auto;
  max-width: 76rem;
}

.bonuses__title {
  font-weight: 700;
  font-size: clamp(
    3.6rem,
    2.0179775281rem + 4.9438202247vw,
    8rem
  );
  text-transform: uppercase;
  color: var(--clr-default-100);
}

.bonuses__subtitle {
  margin-bottom: 5rem;
  font-weight: 400;
  font-size: clamp(
    2.8rem,
    2.4404494382rem + 1.1235955056vw,
    3.8rem
  );
  color: var(--clr-default-100);
}

.games__text {
  margin-bottom: 3rem;
}

.games__text p:not(:last-child) {
  margin-bottom: 2rem;
}

.games__images {
  margin-bottom: 5rem;
  max-width: 120rem;
}

.games__table {
  overflow: auto;
  border: 0.2rem solid var(--clr-default-500);
  border-radius: 3rem;
  padding: 5rem;
  background-color: var(--clr-default-700);
}

.games__table table {
  border-collapse: collapse;
}

.games__table table thead th {
  border-bottom: 0.2rem solid var(--clr-default-600);
  padding-top: 2.5rem;
  padding-right: 1.5rem;
  padding-bottom: 2.5rem;
  padding-left: 1.5rem;
  font-weight: 600;
  font-size: clamp(
    1.6rem,
    1.5280898876rem + 0.2247191011vw,
    1.8rem
  );
  text-align: start;
}

.games__table table thead th:first-child {
  min-width: 23rem;
}

.games__table table thead th:last-child {
  min-width: 23rem;
}

.games__table table tbody tr:not(:last-child) {
  border-bottom: 0.2rem solid var(--clr-default-600);
}

.games__table table tbody td {
  vertical-align: top;
  padding-top: 2.5rem;
  padding-right: 1.5rem;
  padding-bottom: 2.5rem;
  padding-left: 1.5rem;
  font-size: 1.6rem;
  line-height: 150%;
  color: var(--clr-default-100);
}

.games__table table tbody td ul {
  list-style: disc;
  padding-left: 2rem;
}

.live__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.live__image {
  margin-right: 3rem;
  max-width: 69rem;
}

.live__content {
  max-width: 48rem;
}

.live__text {
  margin-bottom: 2.5rem;
}

.live__text p:not(:last-child) {
  margin-bottom: 2rem;
}

.live__list {
  display: grid;
  grid-gap: 0.3rem 4rem;
  grid-template-columns: 1fr 1fr;
  list-style: disc;
  margin-bottom: 5rem;
  padding-left: 2rem;
}

.live__item {
  line-height: 150%;
}

.tournament__text {
  margin-bottom: 3rem;
}

.tournament__text p:not(:last-child) {
  margin-bottom: 2rem;
}

.tournament__image {
  margin-bottom: 5rem;
  max-width: 120rem;
}

.tournament__image img {
  overflow: hidden;
  border-radius: 1.2rem;
}

.tournament__table {
  overflow: auto;
  border: 0.1rem solid var(--clr-default-500);
  border-radius: 3rem;
  padding: 5rem;
  background-color: var(--clr-default-700);
}

.tournament__table table {
  border-collapse: collapse;
  color: var(--clr-default-100);
}

.tournament__table table tbody tr:not(:last-child) {
  border-bottom: 0.1rem solid var(--clr-default-600);
}

.tournament__table table tbody td {
  vertical-align: top;
  padding-top: 2.5rem;
  padding-right: 1.2rem;
  padding-bottom: 2.5rem;
  padding-left: 1.2rem;
  line-height: 150%;
}

.tournament__table table tbody td ul {
  list-style: disc;
  padding-left: 2rem;
}

.tournament__table table tbody td:first-child {
  min-width: 25rem;
}

.promotions__text {
  margin-bottom: 3rem;
}

.promotions__text:not(:last-child) {
  margin-bottom: 2rem;
}

.promotions__image-top {
  margin-bottom: 3rem;
  max-width: 120rem;
}

.promotions__image-top img {
  overflow: hidden;
  border-radius: 1.2rem;
}

.promotions__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5rem;
}

.promotions__image {
  max-width: 59rem;
}

.promotions__image:first-child {
  margin-right: 3rem;
}

.promotions__image img {
  overflow: hidden;
  border-radius: 1.2rem;
}

.exclusive {
  background-image: -webkit-image-set(url(../img/exclusive/bg@2x.jpg) 2x, url(../img/exclusive/bg@1x.jpg) 1x);
  background-size: cover;
  background-repeat: no-repeat;
}

.exclusive__wrapper {
  position: relative;
  padding-top: 11rem;
  padding-bottom: 11rem;
}

.exclusive__content {
  max-width: 72rem;
}

.exclusive__title {
  font-size: clamp(3.6rem, 2.0179775281rem + 4.9438202247vw, 8rem);
  line-height: 90%;
  text-transform: uppercase;
  color: var(--clr-default-100);
}

.exclusive__subtitle {
  margin-bottom: 5rem;
  font-size: clamp(2.8rem, 2.4404494382rem + 1.1235955056vw, 3.8rem);
  text-transform: uppercase;
  color: var(--clr-default-100);
}

.exclusive__link {
  min-width: 24rem;
}

.exclusive__image {
  position: absolute;
  right: -43rem;
  bottom: -0.5rem;
  width: 111rem;
  height: 65rem;
}

.vip__text {
  margin-bottom: 3rem;
}

.vip__text p:not(:last-child) {
  margin-bottom: 2rem;
}

.vip__image {
  margin-bottom: 5rem;
  max-width: 120rem;
}

.vip__image img {
  overflow: hidden;
  border-radius: 1.2rem;
}

.vip__table {
  overflow: auto;
  border-radius: 3rem;
  padding: 5rem;
  background-color: var(--clr-default-700);
}

.vip__table table {
  border-collapse: collapse;
  min-width: 100%;
}

.vip__table table thead th {
  border-bottom: 0.1rem solid var(--clr-default-600);
  padding-top: 2.5rem;
  padding-right: 1.2rem;
  padding-bottom: 2.5rem;
  padding-left: 1.2rem;
  min-width: 21rem;
  text-align: center;
}

.vip__table table thead th:last-child {
  padding-left: 6rem;
  width: 29rem;
  text-align: start;
}

.vip__table table tbody {
  color: var(--clr-default-100);
}

.vip__table table tbody tr:not(:last-child) {
  border-bottom: 0.1rem solid var(--clr-default-600);
}

.vip__table table tbody td {
  padding-top: 2.5rem;
  padding-right: 1.2rem;
  padding-bottom: 2.5rem;
  padding-left: 1.2rem;
  text-align: center;
}

.vip__table table tbody td:last-child {
  padding-left: 6rem;
  text-align: start;
}

.vpn {
  background-color: rgba(255, 255, 255, 0.04);
}

.vpn__body {
  padding-top: 9rem;
  padding-bottom: 9rem;
}

.vpn__top-title {
  text-align: center;
}

.vpn__text {
  border-bottom: 0.1rem solid var(--clr-default-600);
  padding-bottom: 6rem;
  text-align: center;
}

.vpn__text p:not(:last-child) {
  margin-bottom: 2rem;
}

.vpn__wrapper {
  display: flex;
  padding-top: 6rem;
}

.vpn__image {
  flex-shrink: 0;
  margin-right: 2rem;
  max-width: 29rem;
}

.vpn__content {
  margin-left: auto;
  max-width: 80rem;
}

.vpn__content-text {
  padding-bottom: 5rem;
}

.vpn__content-text p:not(:last-child) {
  margin-bottom: 2rem;
}

.payment__text {
  margin-bottom: 3rem;
}

.payment__text p:not(:last-child) {
  margin-bottom: 2rem;
}

.payment__image {
  margin-bottom: 5rem;
  max-width: 120rem;
}

.payment__image img {
  overflow: hidden;
  border-radius: 1.2rem;
}

.payment__table {
  margin-bottom: 5rem;
}

.payment__table table thead th:first-child {
  min-width: 30rem;
}

.payment__table table thead th:nth-child(2) {
  min-width: 30rem;
}

.payment__table table tbody p:not(:last-child) {
  margin-bottom: 2rem;
}

.payment__content-text {
  margin-bottom: 3rem;
}

.payment__content-text p:not(:last-child) {
  margin-bottom: 2rem;
}

.payment__list {
  list-style: disc;
  padding-left: 2rem;
}

.payment__item:not(:last-child) {
  margin-bottom: 1rem;
}

.chat__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chat__content {
  margin-right: 2rem;
  max-width: 69rem;
}

.chat__text {
  margin-bottom: 5rem;
}

.chat__text p:not(:last-child) {
  margin-bottom: 1.2rem;
}

.chat__link {
  min-width: 18rem;
}

.chat__image {
  max-width: 37rem;
}

.conclusion__title {
  margin-bottom: 3rem;
}

.conclusion__image {
  margin-bottom: 3rem;
  max-width: 120rem;
}

.conclusion__image img {
  overflow: hidden;
  border-radius: 1.5rem;
}

.conclusion__text p:not(:last-child) {
  margin-bottom: 2rem;
}

.sale {
  background-image: -webkit-image-set(url(../img/sale/bg@2x.jpg) 2x, url(../img/sale/bg@1x.jpg) 1x);
  background-size: cover;
  background-repeat: no-repeat;
}

.sale__wrapper {
  position: relative;
  padding-top: 14rem;
  padding-bottom: 14rem;
}

.sale__wrapper:before {
  content: "";
  position: absolute;
  left: 8rem;
  bottom: 0;
  width: 54rem;
  height: 60rem;
  background-image: -webkit-image-set(url(../img/sale/zevs@2x.png) 2x, url(../img/sale/zevs@1x.png) 1x);
  background-size: cover;
  background-repeat: no-repeat;
}

.sale__image {
  position: absolute;
  left: -13.2rem;
  bottom: -0.7rem;
  width: 63rem;
  height: 68rem;
}

.sale__content {
  margin-left: auto;
  max-width: 59rem;
}

.sale__title {
  font-size: clamp(3.6rem, 2.0179775281rem + 4.9438202247vw, 4rem);
  line-height: 130%;
  color: var(--clr-default-100);
}

.sale__subtitle {
  margin-bottom: 5rem;
  font-size: clamp(2.8rem, 2.4404494382rem + 1.1235955056vw, 3.8rem);
  text-transform: uppercase;
  color: var(--clr-default-100);
}

.sale__link {
  min-width: 24rem;
}

.faq {
  position: relative;
}

.faq__wrapper {
  border-radius: 1.2rem;
  padding-right: 3rem;
  padding-left: 3rem;
  background-color: var(--clr-default-600);
}

.faq__wrapper:not(:last-child) {
  margin-bottom: 1.2rem;
}

.faq__wrapper.active .faq__question span {
  background: initial;
  background: #f3f5f6;
  transition: transform var(--transition), background-color var(--transition);
}

.faq__wrapper.active .faq__question::before {
  transform: rotate(-180deg);
  transition: transform var(--transition);
}

.faq__wrapper.active .faq__answer {
  padding-bottom: 3rem;
  font-weight: 400;
  font-size: clamp(
    1.6rem,
    1.5280898876rem + 0.2247191011vw,
    1.8rem
  );
  transition: max-height var(--transition), padding-bottom var(--transition);
}

.faq__question {
  position: relative;
  padding-top: 3rem;
  padding-right: 4rem;
  padding-bottom: 2.5rem;
  width: 100%;
  font-weight: 600;
  font-size: clamp(
    1.6rem,
    1.4561797753rem + 0.4494382022vw,
    2rem
  );
  line-height: 150%;
  color: var(--clr-default-100);
}

.faq__question::before {
  content: "";
  position: absolute;
  right: 0;
  top: 4rem;
  width: 2.2rem;
  height: 1.2rem;
  background-image: url(../img/faq/arrow.svg);
  background-size: cover;
  background-repeat: no-repeat;
  transition: transform var(--transition);
}

.faq__answer {
  overflow: hidden;
  max-height: 0;
  box-sizing: content-box;
  font-size: clamp(
    1.6rem,
    1.5280898876rem + 0.2247191011vw,
    1.8rem
  );
  line-height: 150%;
  color: var(--clr-default-300);
  transition: max-height var(--transition), padding-bottom var(--transition);
}

.faq__answer p:not(:last-child) {
  margin-bottom: 1.5rem;
}

@media (max-width: 75em) {
  .section {
    padding-top: 15rem;
  }

  .hero__content {
    max-width: 45rem !important;
  }

  .info__table table td:first-child {
    min-width: 35rem;
  }

  .benefits__wrapper {
    flex-direction: column;
  }

  .benefits__list:first-child {
    margin-right: initial;
    margin-bottom: 3rem;
  }

  .benefits__list {
    min-width: initial;
  }

  .registration__wrapper {
    flex-direction: column-reverse;
  }

  .registration__image img {
    height: auto;
  }

  .registration__image {
    margin-top: 3rem;
    margin-right: auto;
    margin-left: auto;
  }

  .registration__content {
    max-width: initial;
  }

  .bonuses__wrapper::before {
    display: none;
  }

  .bonuses__content {
    max-width: initial;
  }

  .bonuses__title {
    text-align: center;
  }

  .bonuses__subtitle {
    text-align: center;
  }

  .bonuses__link {
    margin-left: auto;
    margin-right: auto;
  }

  .games__table table thead th:first-child {
    min-width: 15rem;
  }

  .exclusive__title {
    text-align: center;
  }

  .exclusive__subtitle {
    text-align: center;
  }

  .exclusive__link {
    margin-right: auto;
    margin-left: auto;
  }

  .chat__content {
    max-width: 55rem;
  }

  .sale__wrapper:before {
    display: none;
  }

  .sale__image {
    display: none;
  }

  .sale__content {
    max-width: initial;
  }

  .sale__title {
    text-align: center;
  }

  .sale__subtitle {
    text-align: center;
  }

  .sale__link {
    margin-right: auto;
    margin-left: auto;
  }
}

@media (max-width: 62em) {
  .section {
    padding-top: 8rem;
  }

  .menu__list {
    flex-direction: column;
    margin: auto;
    padding-block: 4rem;
  }

  .menu__item:not(:last-child) {
    margin-right: 0;
    margin-bottom: 3rem;
  }

  .menu__item a {
    font-weight: 700;
    font-size: 3rem;
  }

  .menu {
    position: fixed;
    right: -100%;
    top: 0;
    z-index: 10;
    display: flex;
    overflow: auto;
    width: 100%;
    height: 100%;
    max-width: 32rem;
    background-color: var(--clr-default-900);
    transition: right var(--transition);
  }

  .menu--active {
    right: 0;
    transition: right var(--transition);
  }

  .bonus {
    margin-left: auto;
  }

  .burger {
    position: relative;
    z-index: 15;
    display: block;
    width: 2.5rem;
    height: 2rem;
    background-color: transparent;
  }

  .burger::after,
  .burger::before,
  .burger span {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 0.4rem;
    background-color: #fff;
  }

  .burger::before {
    top: 0;
    transition: transform var(--transition), top var(--transition);
  }

  .burger::after {
    bottom: 0;
    transition: transform var(--transition), bottom var(--transition);
  }

  .burger span {
    top: 0.8rem;
    transition: transform var(--transition);
  }

  .burger--active::before {
    top: 0.8rem;
    transform: rotate(45deg);
    transition: transform var(--transition), top var(--transition);
  }

  .burger--active::after {
    bottom: 0.8rem;
    transform: rotate(-45deg);
    transition: transform var(--transition), bottom var(--transition);
  }

  .burger--active span {
    transform: scale(0);
    transition: transform var(--transition);
  }

  .hero__wrapper {
    flex-direction: column;
  }

  .hero__content {
    margin-right: initial !important;
    margin-bottom: 5rem;
    max-width: initial !important;
  }

  .info__table table td:first-child {
    min-width: 25rem;
  }

  .info__table {
    padding: 3rem;
  }

  .slots__table table {
    min-width: 97rem;
  }

  .bonuses__wrapper {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }

  .games__table table thead th:last-child {
    min-width: initial;
  }

  .games__table {
    padding: 3rem;
  }

  .live__wrapper {
    flex-direction: column-reverse;
  }

  .live__image {
    margin-right: initial;
  }

  .live__content {
    margin-bottom: 5rem;
    max-width: initial;
  }

  .live__list {
    display: block;
    grid-gap: initial;
    margin-bottom: 3rem;
  }

  .live__item:not(:last-child) {
    margin-bottom: 0.3rem;
  }

  .tournament__table table tbody td:first-child {
    min-width: 15rem;
  }

  .tournament__table {
    padding: 3rem;
  }

  .exclusive__wrapper {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }

  .vip__table table thead th:last-child {
    padding-left: 1.2rem;
    width: initial;
  }

  .vip__table table thead th {
    min-width: 17rem;
    text-align: start;
  }

  .vip__table table tbody td:last-child {
    padding-left: 1.2rem;
  }

  .vip__table table tbody td {
    text-align: start;
  }

  .vpn__body {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .vpn__text {
    padding-bottom: 3rem;
  }

  .vpn__wrapper {
    flex-direction: column-reverse;
    padding-top: 3rem;
  }

  .vpn__image {
    margin-right: auto;
    margin-left: auto;
  }

  .vpn__content {
    margin-bottom: 5rem;
    max-width: initial;
  }

  .chat__wrapper {
    flex-direction: column;
  }

  .chat__content {
    margin-right: initial;
    margin-bottom: 5rem;
    max-width: initial;
  }

  .chat__text {
    margin-bottom: 3rem;
  }

  .sale__wrapper {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }

  .faq__wrapper:not(:last-child) {
    margin-bottom: 2rem;
  }
}

@media (max-width: 48em) {
  .info__table table td:first-child {
    min-width: 20rem;
  }

  .info__table {
    padding: 2rem;
  }
}

@media (max-width: 112.5em) {
  .hero__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .hero__content {
    margin-right: 2rem;
    max-width: 55rem;
  }

  .hero__image {
    position: initial;
    width: initial;
    height: initial;
  }
}

@media (max-width: 93.75em) {
  .exclusive__content {
    max-width: initial;
  }

  .exclusive__image {
    display: none;
  }
}

@media (max-width: 47.9375em) {
  .section {
    padding-top: 6rem;
  }

  .hero__content {
    margin-bottom: 3rem;
  }

  .slots__table table thead td {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .slots__table table tbody td {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .slots__table {
    padding: 2rem;
  }

  .games__table table {
    min-width: 73rem;
  }

  .faq__question::before {
    right: -1rem;
    top: 3rem;
  }

  .faq__question {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}

@media (max-width: 35.9375em) {
  .section {
    padding-top: 3rem;
  }

  .main__link {
    width: initial;
    min-width: 100%;
  }

  .logo {
    max-width: 12rem;
  }

  .burger {
    margin-left: auto;
  }

  .header__wrapper {
    min-height: 8rem;
  }

  .footer {
    margin-bottom: 3rem;
  }

  .hero__wrapper {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .hero__text {
    margin-bottom: 3rem;
  }

  .info__table table td {
    padding: 1.5rem;
  }

  .benefits__item--pros::before,
  .benefits__item--cons::before {
    width: 1.8rem;
    height: 2.6rem;
  }

  .benefits__item {
    padding-left: 2.5rem;
  }

  .registration__list {
    margin-bottom: 3rem;
  }

  .slots__image {
    margin-bottom: 3rem;
  }

  .games__images {
    margin-bottom: 3rem;
  }

  .games__table table thead th:first-child {
    min-width: 13rem;
  }

  .games__table table tbody td {
    padding: 1.5rem;
  }

  .games__table {
    padding: 2rem;
  }

  .tournament__image {
    margin-bottom: 3rem;
  }

  .tournament__table table tbody td:first-child {
    min-width: 10rem;
  }

  .tournament__table table tbody td {
    padding-top: 1.5rem;
    padding-right: 1rem;
    padding-bottom: 1.5rem;
    padding-left: 1rem;
  }

  .tournament__table table {
    min-width: 50rem;
  }

  .tournament__table {
    padding: 2rem;
  }

  .promotions__image-top {
    margin-bottom: 1rem;
  }

  .promotions__wrapper {
    margin-bottom: 3rem;
  }

  .promotions__image:first-child {
    margin-right: 1rem;
  }

  .exclusive__subtitle {
    margin-bottom: 3rem;
  }

  .vip__image {
    margin-bottom: 3rem;
  }

  .vip__table table thead th {
    min-width: 14rem;
  }

  .vip__table {
    padding: 2rem;
  }

  .vpn__body {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .vpn__content {
    margin-bottom: 3rem;
  }

  .vpn__content-text {
    padding-bottom: 3rem;
  }

  .payment__image {
    margin-bottom: 3rem;
  }

  .payment__table table thead th:first-child {
    min-width: 15rem;
  }

  .payment__table table thead th:nth-child(2) {
    min-width: 15rem;
  }

  .payment__table {
    margin-bottom: 3rem;
  }

  .faq__wrapper:not(:last-child) {
    margin-bottom: 1.5rem;
  }

  .faq__wrapper.active .faq__answer {
    padding-bottom: 2rem;
  }

  .faq__wrapper {
    padding-left: 2rem;
  }
}