.vpn {
  background-color: rgba(255, 255, 255, 0.04);

  &__body {
    padding-top: 9rem;
    padding-bottom: 9rem;

    @media (max-width: em(992)) {
      padding-top: 6rem;
      padding-bottom: 6rem;
    }

    @media (max-width: em(575)) {
      padding-top: 3rem;
      padding-bottom: 3rem;
    }
  }

  &__top-title {
    text-align: center;
  }

  &__title {
  }

  &__text {
    border-bottom: 0.1rem solid var(--clr-default-600);
    padding-bottom: 6rem;
    text-align: center;

    p:not(:last-child) {
      margin-bottom: 2rem;
    }

    @media (max-width: em(992)) {
      padding-bottom: 3rem;
    }
  }

  &__wrapper {
    display: flex;
    padding-top: 6rem;

    @media (max-width: em(992)) {
      flex-direction: column-reverse;
      padding-top: 3rem;
    }
  }

  &__image {
    flex-shrink: 0;
    margin-right: 2rem;
    max-width: 29rem;

    @media (max-width: em(992)) {
      margin-right: auto;
      margin-left: auto;
    }
  }

  &__content {
    margin-left: auto;
    max-width: 80rem;

    @media (max-width: em(992)) {
      margin-bottom: 5rem;
      max-width: initial;
    }

    @media (max-width: em(575)) {
      margin-bottom: 3rem;
    }
  }

  &__content-text {
    padding-bottom: 5rem;

    p:not(:last-child) {
      margin-bottom: 2rem;
    }

    @media (max-width: em(575)) {
      padding-bottom: 3rem;
    }
  }

  &__link {
  }
}

