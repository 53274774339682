.registration {
  &__wrapper {
    display: flex;
    justify-content: space-between;

    @media (max-width: em(1200)) {
      flex-direction: column-reverse;
    }
  }

  &__image {
    margin-right: 2rem;
    max-width: 42rem;

    img {
      height: 100%;

      @media (max-width: em(1200)) {
        height: auto;
      }
    }

    @media (max-width: em(1200)) {
      margin-top: 3rem;
      margin-right: auto;
      margin-left: auto;
    }
  }

  &__content {
    max-width: 69rem;

    @media (max-width: em(1200)) {
      max-width: initial;
    }
  }

  &__text {
    margin-bottom: 2.5rem;
  }

  &__list {
    list-style: decimal;
    margin-bottom: 5rem;
    padding-left: 2rem;
    line-height: 150%;

    @media (max-width: em(575)) {
      margin-bottom: 3rem;
    }
  }

  &__list-item {
    &:not(:last-child) {
      margin-bottom: 2rem;
    }
  }

  &__mark-list {
    list-style: disc;
    padding-top: 1rem;
    padding-left: 2.5rem;

    li {
      line-height: 150%;
    }
  }

  &__link {
  }
}
