.benefits {
  &__wrapper {
    display: flex;

    @media (max-width: em(1200)) {
      flex-direction: column;
    }
  }

  &__list {
    min-width: 59rem;

    &:first-child {
      margin-right: 3rem;

      @media (max-width: em(1200)) {
        margin-right: initial;
        margin-bottom: 3rem;
      }
    }

    @media (max-width: em(1200)) {
      min-width: initial;
    }
  }

  &__item {
    padding-left: 3.5rem;
    font-size: fluid-font(2rem, 1.8rem);
    line-height: 150%;
    color: var(--clr-default-100);

    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    span {
      color: var(--clr-default-400);
    }

    &--pros,
    &--cons {
      position: relative;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 2.4rem;
        height: 3.6rem;
        background-size: contain;
        background-repeat: no-repeat;

        @media (max-width: em(575)) {
          width: 1.8rem;
          height: 2.6rem;
        }
      }
    }

    &--pros {
      &::before {
        background-image: url(../img/benefits/pros.svg);
      }
    }

    &--cons {
      &::before {
        background-image: url(../img/benefits/cons.svg);
      }
    }

    @media (max-width: em(575)) {
      padding-left: 2.5rem;
    }
  }
}
