.hero {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: -10rem;
    z-index: -1;
    width: 100%;
    height: 94rem;
    background-image: -webkit-image-set(
      url(../img/hero/bg@2x.png) 2x,
      url(../img/hero/bg@1x.png) 1x
    );
    background-size: cover;
    background-repeat: no-repeat;
  }
  &__wrapper {
    padding-top: 18rem;
    padding-bottom: 37rem;

    @media (max-width: em(1800)) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 5rem;
      padding-bottom: 5rem;
    }

    @media (max-width: em(992)) {
      flex-direction: column;
    }

    @media (max-width: em(575)) {
      padding-top: 3rem;
      padding-bottom: 3rem;
    }
  }

  &__content {
    position: relative;
    z-index: 1;
    max-width: 67rem;

    @media (max-width: em(1800)) {
      margin-right: 2rem;
      max-width: 55rem;
    }

    @media (max-width: em(1200)) {
      max-width: 45rem !important;
    }

    @media (max-width: em(992)) {
      margin-right: initial !important;
      margin-bottom: 5rem;
      max-width: initial !important;
    }

    @media (max-width: em(767)) {
      margin-bottom: 3rem;
    }
  }

  &__title {
    padding-bottom: 2.4rem;
    font-weight: 600;
    font-size: fluid-font(5.4rem, 3.8rem);
    text-transform: uppercase;
    color: var(--clr-default-100);
  }

  &__text {
    margin-bottom: 5rem;

    p:not(:last-child) {
      margin-bottom: 2rem;
    }

    @media (max-width: em(575)) {
      margin-bottom: 3rem;
    }
  }

  &__link {
  }

  &__image {
    position: absolute;
    right: -35rem;
    top: 9rem;
    width: 129rem;
    height: 116rem;

    @media (max-width: em(1800)) {
      position: initial;
      width: initial;
      height: initial;
    }
  }
}
