.conclusion {

  &__title {
    margin-bottom: 3rem;
  }

  &__image {
    margin-bottom: 3rem;
    max-width: 120rem;

    img {
      overflow: hidden;
      border-radius: 1.5rem;
    }
  }

  &__text {
    p:not(:last-child) {
      margin-bottom: 2rem;
    }
  }
}
