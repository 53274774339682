.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.section {
  padding-top: 19rem;

  @media (max-width: em(1200)) {
    padding-top: 15rem;
  }

  @media (max-width: em(992)) {
    padding-top: 8rem;
  }

  @media (max-width: em(767)) {
    padding-top: 6rem;
  }

  @media (max-width: em(575)) {
    padding-top: 3rem;
  }
}

.title {
  margin-bottom: 2rem;
  font-weight: 600;
  font-size: fluid-font(4.2rem, 3.2rem);
  line-height: 130%;
  text-transform: uppercase;
  color: var(--clr-default-100);
}

.description {
  font-size: fluid-font(1.8rem, 1.6rem);
  line-height: 150%;
}

.main__link {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10rem;
  padding-right: 2rem;
  padding-left: 2rem;
  width: max-content;
  min-width: 36rem;
  min-height: 7rem;
  max-width: 45rem;
  box-shadow: 0px 5px 0px #205e15, 0px 14px 48px rgba(55, 164, 37, 0.35);
  font-weight: 700;
  font-size: fluid-font(2rem, 1.8rem);
  line-height: 120%;
  text-align: center;
  text-transform: uppercase;
  color: var(--clr-default-100);
  background-color: var(--clr-primary-500);
  transition: box-shadow var(--transition);

  @media (max-width: em(575)) {
    width: initial;
    min-width: 100%;
  }

  &:hover {
    box-shadow: 0px 2px 0px #205e15, 0px 14px 48px rgba(55, 164, 37, 0.35);
    transition: box-shadow var(--transition);
  }

  &:active {
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.1),
        rgba(255, 255, 255, 0.1)
      ),
      #37a425;
  }

  &--bonus {
    box-shadow: 0px 5px 0px #af5300, 0px 14px 48px rgba(175, 83, 0, 0.35);
    background-color: var(--clr-primary-600);

    &:hover {
      box-shadow: 0px 2px 0px #af5300, 0px 14px 48px rgba(175, 83, 0, 0.35);
    }

    &:active {
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.1),
          rgba(255, 255, 255, 0.1)
        ),
        #fa7600;
    }
  }
}
