.header {
  display: flex;
  align-items: center;
  justify-content: center;

  &__wrapper {
    display: flex;
    align-items: center;
    border-bottom: 0.2rem solid var(--clr-default-500);
    min-height: 10rem;

    @media (max-width: em(575)) {
      min-height: 8rem;
    }
  }
}
