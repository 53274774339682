.games {
  &__text {
    margin-bottom: 3rem;

    p:not(:last-child) {
      margin-bottom: 2rem;
    }
  }

  &__images {
    margin-bottom: 5rem;
    max-width: 120rem;

    @media (max-width: em(575)) {
      margin-bottom: 3rem;
    }
  }

  &__table {
    overflow: auto;
    border: 0.2rem solid var(--clr-default-500);
    border-radius: 3rem;
    padding: 5rem;
    background-color: var(--clr-default-700);

    table {
      border-collapse: collapse;

      thead {

        th {
          border-bottom: 0.2rem solid var(--clr-default-600);
          padding-top: 2.5rem;
          padding-right: 1.5rem;
          padding-bottom: 2.5rem;
          padding-left: 1.5rem;
          font-weight: 600;
          font-size: fluid-font(1.8rem, 1.6rem);
          text-align: start;

          &:first-child {
            min-width: 23rem;

            @media (max-width: em(1200)) {
              min-width: 15rem;
            }

            @media (max-width: em(575)) {
              min-width: 13rem;
            }
          }

          &:last-child {
            min-width: 23rem;

            @media (max-width: em(992)) {
              min-width: initial;
            }
          }
        }
      }

      tbody {
        tr {
          &:not(:last-child) {
            border-bottom: 0.2rem solid var(--clr-default-600);
          }
        }

        td {
          vertical-align: top;
          padding-top: 2.5rem;
          padding-right: 1.5rem;
          padding-bottom: 2.5rem;
          padding-left: 1.5rem;
          font-size: 1.6rem;
          line-height: 150%;
          color: var(--clr-default-100);

          ul {
            list-style: disc;
            padding-left: 2rem;
          }

          @media (max-width: em(575)) {
            padding: 1.5rem;
          }
        }
      }
      @media (max-width: em(767)) {
        min-width: 73rem;
      }
    }

    @media (max-width: em(992)) {
      padding: 3rem;
    }

    @media (max-width: em(575)) {
      padding: 2rem;
    }
  }
}

