.sale {
  background-image: -webkit-image-set(
    url(../img/sale/bg@2x.jpg) 2x,
    url(../img/sale/bg@1x.jpg) 1x
  );
  background-size: cover;
  background-repeat: no-repeat;

  &__wrapper {
    position: relative;
    padding-top: 14rem;
    padding-bottom: 14rem;

    @media (max-width: em(992)) {
      padding-top: 10rem;
      padding-bottom: 10rem;
    }

    &:before {
      content: "";
      position: absolute;
      left: 8rem;
      bottom: 0;
      width: 54rem;
      height: 60rem;
      background-image: -webkit-image-set(
        url(../img/sale/zevs@2x.png) 2x,
        url(../img/sale/zevs@1x.png) 1x
      );
      background-size: cover;
      background-repeat: no-repeat;

      @media (max-width: em(1200)) {
        display: none;
      }
    }
  }

  &__image {
    position: absolute;
    left: -13.2rem;
    bottom: -0.7rem;
    width: 63rem;
    height: 68rem;

    @media (max-width: em(1200)) {
      display: none;
    }
  }

  &__content {
    margin-left: auto;
    max-width: 59rem;

    @media (max-width: em(1200)) {
      max-width: initial;
    }
  }

  &__title {
    font-size: clamp(3.6rem, 2.0179775281rem + 4.9438202247vw, 4rem);
    line-height: 130%;
    color: var(--clr-default-100);

    @media (max-width: em(1200)) {
      text-align: center;
    }
  }

  &__subtitle {
    margin-bottom: 5rem;
    font-size: clamp(2.8rem, 2.4404494382rem + 1.1235955056vw, 3.8rem);
    text-transform: uppercase;
    color: var(--clr-default-100);

    @media (max-width: em(1200)) {
      text-align: center;
    }
  }

  &__link {
    min-width: 24rem;

    @media (max-width: em(1200)) {
      margin-right: auto;
      margin-left: auto;
    }
  }
}
