.info {
  position: relative;
  z-index: 1;
  margin-top: 3rem;
  &__title {
    text-align: center;
  }

  &__text {
    margin-right: auto;
    margin-bottom: 3rem;
    margin-left: auto;
    max-width: 80rem;
    text-align: center;

    p:not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }

  &__table {
    overflow: auto;
    border: 0.2rem solid var(--clr-default-500);
    border-radius: 3rem;
    padding: 5rem;
    background-color: var(--clr-default-700);

    table {
      border-collapse: collapse;
      min-width: 61rem;

      tr {
        &:not(:last-child) {
          border-bottom: 0.2rem solid var(--clr-default-600);
        }
      }

      td {
        vertical-align: top;
        padding-top: 2.5rem;
        padding-right: 1.5rem;
        padding-bottom: 2.5rem;
        padding-left: 1.5rem;
        line-height: 150%;

        &:first-child {
          min-width: 45rem;

          @media (max-width: em(1200)) {
            min-width: 35rem;
          }

          @media (max-width: em(992)) {
            min-width: 25rem;
          }

          @media (max-width: em(768)) {
            min-width: 20rem;
          }
        }

        &:last-child {
          font-size: 1.6rem;
          color: var(--clr-default-100);
        }

        a {
          text-decoration: underline;
          color: var(--clr-primary-400);
        }

        @media (max-width: em(575)) {
          padding: 1.5rem;
        }
      }
    }

    @media (max-width: em(992)) {
      padding: 3rem;
    }

    @media (max-width: em(768)) {
      padding: 2rem;
    }
  }
}

