.bonus {
  margin-left: 5rem;
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0.2rem solid var(--clr-primary-400);
    border-radius: 10rem;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    width: max-content;
    min-width: 16rem;
    min-height: 5rem;
    max-width: 25rem;
    font-weight: 700;
    line-height: 70%;
    text-align: center;
    text-transform: uppercase;
    color: var(--clr-primary-400);
    transition: box-shadow var(--transition);

    &:hover {
      box-shadow: 0px 14px 48px rgba(55, 164, 37, 0.35);
      transition: box-shadow var(--transition);
    }
  }

  @media (max-width: em(992)) {
    margin-left: auto;
  }
}
