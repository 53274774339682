.promotions {

  &__text {
    margin-bottom: 3rem;

    &:not(:last-child) {
      margin-bottom: 2rem;
    }
  }

  &__image-top {
    margin-bottom: 3rem;
    max-width: 120rem;

    @media (max-width: em(575)) {
      margin-bottom: 1rem;
    }

    img {
      overflow: hidden;
      border-radius: 1.2rem;
    }
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5rem;

    @media (max-width: em(575)) {
      margin-bottom: 3rem;
    }
  }

  &__image {
    max-width: 59rem;

    &:first-child {
      margin-right: 3rem;

      @media (max-width: em(575)) {
        margin-right: 1rem;
      }
    }

    img {
      overflow: hidden;
      border-radius: 1.2rem;
    }
  }
}


