.tournament {
  &__text {
    margin-bottom: 3rem;

    p:not(:last-child) {
      margin-bottom: 2rem;
    }
  }

  &__image {
    margin-bottom: 5rem;
    max-width: 120rem;

    @media (max-width: em(575)) {
      margin-bottom: 3rem;
    }

    img {
      overflow: hidden;
      border-radius: 1.2rem;
    }
  }

  &__table {
    overflow: auto;
    border: 0.1rem solid var(--clr-default-500);
    border-radius: 3rem;
    padding: 5rem;
    background-color: var(--clr-default-700);

    table {
      border-collapse: collapse;
      color: var(--clr-default-100);

      tbody {
        tr {
          &:not(:last-child) {
            border-bottom: 0.1rem solid var(--clr-default-600);
          }
        }

        td {
          vertical-align: top;
          padding-top: 2.5rem;
          padding-right: 1.2rem;
          padding-bottom: 2.5rem;
          padding-left: 1.2rem;
          line-height: 150%;

          ul {
            list-style: disc;
            padding-left: 2rem;
          }

          &:first-child {
            min-width: 25rem;

            @media (max-width: em(992)) {
              min-width: 15rem;
            }

            @media (max-width: em(575)) {
              min-width: 10rem;
            }
          }

          @media (max-width: em(575)) {
            padding-top: 1.5rem;
            padding-right: 1rem;
            padding-bottom: 1.5rem;
            padding-left: 1rem;
          }
        }
      }

      @media (max-width: em(575)) {
        min-width: 50rem;
      }
    }

    @media (max-width: em(992)) {
      padding: 3rem;
    }

    @media (max-width: em(575)) {
      padding: 2rem;
    }
  }
}
