.vip {
  &__text {
    margin-bottom: 3rem;

    p:not(:last-child) {
      margin-bottom: 2rem;
    }
  }

  &__image {
    margin-bottom: 5rem;
    max-width: 120rem;

    @media (max-width: em(575)) {
      margin-bottom: 3rem;
    }

    img {
      overflow: hidden;
      border-radius: 1.2rem;
    }
  }

  &__table {
    overflow: auto;
    border-radius: 3rem;
    padding: 5rem;
    background-color: var(--clr-default-700);

    table {
      border-collapse: collapse;
      min-width: 100%;
      thead {
        th {
          border-bottom: 0.1rem solid var(--clr-default-600);
          padding-top: 2.5rem;
          padding-right: 1.2rem;
          padding-bottom: 2.5rem;
          padding-left: 1.2rem;
          min-width: 21rem;
          text-align: center;

          &:last-child {
            padding-left: 6rem;
            width: 29rem;
            text-align: start;

            @media (max-width: em(992)) {
              padding-left: 1.2rem;
              width: initial;
            }
          }

          @media (max-width: em(992)) {
            min-width: 17rem;
            text-align: start;
          }

          @media (max-width: em(575)) {
            min-width: 14rem;
          }
        }
      }
      tbody {
        color: var(--clr-default-100);
        tr {
          &:not(:last-child) {
            border-bottom: 0.1rem solid var(--clr-default-600);
          }
        }
        td {
          padding-top: 2.5rem;
          padding-right: 1.2rem;
          padding-bottom: 2.5rem;
          padding-left: 1.2rem;
          text-align: center;

          &:last-child {
            padding-left: 6rem;
            text-align: start;

            @media (max-width: em(992)) {
              padding-left: 1.2rem;
            }
          }

          @media (max-width: em(992)) {
            text-align: start;
          }
        }
      }
    }

    @media (max-width: em(575)) {
      padding: 2rem;
    }
  }
}
