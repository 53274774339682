.footer {
  margin-bottom: 5rem;

  @media (max-width: em(575)) {
    margin-bottom: 3rem;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
  }

  &__logo {
    margin-bottom: 1.6rem;
  }

  &__text {
    margin-bottom: 2rem;

    p:not(:last-child) {
      margin-bottom: 1.2rem;
    }
  }

  &__policy {
    font-size: fluid-font(1.8rem, 1.4rem);
  }
}
