.slots {

  &__text {
    margin-bottom: 3rem;

    p:not(:last-child) {
      margin-bottom: 2rem;
    }
  }

  &__image {
    margin-bottom: 5rem;
    border-radius: 1.2rem;
    max-width: 120rem;

    @media (max-width: em(575)) {
      margin-bottom: 3rem;
    }
  }

  &__table {
    overflow: auto;
    border: 0.1rem solid var(--clr-default-500);
    border-radius: 3.2rem;
    padding: 3rem;
    background-color: var(--clr-default-700);

    table {
      border-collapse: collapse;
      min-width: 100%;

      @media (max-width: em(992)) {
        min-width: 97rem;
      }

      thead {
        tr {
          border-bottom: 0.2rem solid var(--clr-default-600);
        }
        td {
          vertical-align: top;
          padding-top: 2.5rem;
          padding-right: 1.5rem;
          padding-bottom: 3rem;
          padding-left: 1.5rem;
          font-size: 1.8rem;

          @media (max-width: em(767)) {
            padding-top: 2rem;
            padding-bottom: 2rem;
          }
        }
      }

      tbody {
        tr {
          &:not(:last-child) {
            border-bottom: 0.2rem solid var(--clr-default-600);
          }
        }
        td {
          vertical-align: top;
          padding-top: 2.5rem;
          padding-right: 1.5rem;
          padding-bottom: 3rem;
          padding-left: 1.5rem;
          color: var(--clr-default-100);

          @media (max-width: em(767)) {
            padding-top: 2rem;
            padding-bottom: 2rem;
          }
        }
      }
    }

    @media (max-width: em(767)) {
      padding: 2rem;
    }
  }
}

