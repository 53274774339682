.payment {

  &__title {
  }

  &__text {
    margin-bottom: 3rem;

    p:not(:last-child) {
      margin-bottom: 2rem;
    }
  }

  &__image {
    margin-bottom: 5rem;
    max-width: 120rem;

    @media (max-width: em(575)) {
      margin-bottom: 3rem;
    }

    img {
      overflow: hidden;
      border-radius: 1.2rem;
    }
  }

  &__table {
    margin-bottom: 5rem;
    table {
      thead {
        th {
          &:first-child {
            min-width: 30rem;

            @media (max-width: em(575)) {
              min-width: 15rem;
            }
          }

          &:nth-child(2) {
            min-width: 30rem;

            @media (max-width: em(575)) {
              min-width: 15rem;
            }
          }
        }
      }

      tbody {
        p {
          &:not(:last-child) {
            margin-bottom: 2rem;
          }
        }
      }
    }

    @media (max-width: em(575)) {
      margin-bottom: 3rem;
    }
  }

  &__content-text {
    margin-bottom: 3rem;

    p:not(:last-child) {
      margin-bottom: 2rem;
    }
  }

  &__list {
    list-style: disc;
    padding-left: 2rem;
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
}

