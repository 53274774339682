.live {

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: em(992)) {
      flex-direction: column-reverse;
    }
  }

  &__image {
    margin-right: 3rem;
    max-width: 69rem;

    @media (max-width: em(992)) {
      margin-right: initial;
    }
  }

  &__content {
    max-width: 48rem;

    @media (max-width: em(992)) {
      margin-bottom: 5rem;
      max-width: initial;
    }
  }

  &__text {
    margin-bottom: 2.5rem;

    p:not(:last-child) {
      margin-bottom: 2rem;
    }
  }

  &__list {
    display: grid;
    grid-gap: 0.3rem 4rem;
    grid-template-columns: 1fr 1fr;
    list-style: disc;
    margin-bottom: 5rem;
    padding-left: 2rem;

    @media (max-width: em(992)) {
      display: block;
      grid-gap: initial;
      margin-bottom: 3rem;
    }
  }

  &__item {
    line-height: 150%;

    @media (max-width: em(992)) {
      &:not(:last-child) {
        margin-bottom: 0.3rem;
      }
    }
  }

  &__link {
  }
}

